.MVHR-shop-FD {
  background: white;
  height: 100%;
}

.MVHR-shop-FD>.grid {
  margin: 0 !important;
  height: 100%;
}


.MVHR-shop-FD>.grid .row,
.MVHR-shop-FD>.grid .column {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .MVHR-shop-FD>.grid {
    min-height: unset;
  }
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.logo h2 {
  margin: 0 !important;
}

.logo img {
  margin-right: 2.5em;
  width: 6em;
  border-radius: 100%;
}

@media only screen and (max-width: 767px) {
  .logo {
    margin-top: 0;
    margin-bottom: 0.8em;
  }

  .logo img {
    width: 3em;
    margin-right: 1em;
  }

  .logo h2 {
    font-size: medium !important;
  }

}

.filter-preview-controls {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 1em;
}

.filter-preview-controls>* {
  margin-left: 1em;
}

.filter-preview {
  background: grey;
  border: solid 20px transparent;
  background-size: cover !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 0;
  height: 0;
  transition: all 1s ease-in-out;
  opacity: 0;
  border-radius: 10px;
  box-shadow: #00000059 0 0 15px 0px;
  min-width: 50px;
  min-height: 50px;
}

.preview-wrapper {
  min-height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  box-sizing: border-box;
  padding: 4em 5em;
}

@media only screen and (max-width: 767px) {
  .preview-wrapper {
    border: dashed 1px #114f555e;
    margin: 1em 0;
    border-radius: 0.5em;
  }
}

.filter-tag {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
  background: black;
  background: url('/public/Tag.png');
  background-size: cover;
  background-position: bottom;
  width: 35px;
  height: 75px;
  border-radius: 2px;
  box-shadow: #00000059 0 0 15px 0px;
}

.filter-tag-top {
  transform: rotate(0deg);
  right: unset;
  left: calc(50% - 17.5px);
  top: -67px;
}

.filter-tag-top.mobile {
  transform: rotate(0deg) scale(0.8);
}

.filter-tag-right {
  transform: rotate(90deg);
  right: -52px;
  left: unset;
  top: calc(50% - 37.5px);
}

.filter-tag-right.mobile {
  transform: rotate(90deg) scale(0.8);
}

.filter-tag-top-dual {
  transform: rotate(0deg);
  right: unset;
  left: calc(90% - 17.5px);
  top: -67px;
}

.filter-tag-top-dual.mobile {
  transform: rotate(0deg) scale(0.8);
}

.filter-tag-right-dual {
  transform: rotate(90deg);
  right: -52px;
  left: unset;
  top: calc(10% - 37.5px);
}

.filter-tag-right-dual.mobile {
  transform: rotate(90deg) scale(0.8);
  ;
}

.preview-panel {
  overflow: hidden;
}

@media only screen and (min-width: 767px) {
  .preview-panel {
    height: 100%;
    min-height: 300px;
  }
}

.heightInput,
.widthInput {
  width: 100%;
}

.heightInput input,
.widthInput input {
  width: 4em;
}

.filter-height-dimension {
  position: absolute;
  left: -5.5em;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  transform: rotate(270deg);
}

.filter-width-dimension {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -4.5em;
  display: flex;
  justify-content: center;
}

.filter-width-ruler {
  height: 1px;
  background: black;
  position: absolute;
  left: -1em;
  right: -1em;
  bottom: -2.5em;
  transition: opacity 0.3s ease-in-out;
}

.filter-height-ruler {
  width: 1px;
  background: black;
  position: absolute;
  top: -1em;
  bottom: -1em;
  left: -2.5em;
  transition: opacity 0.3s ease-in-out;
}

.filter-screenshot-model {
  position: absolute;
  bottom: -4em;
  font-size: 20px;
  left: -0.5em;
}

.filter-screenshot-model>div {
  font-size: small;
  font-weight: normal;
}

.filter-screenshot-disclaimer {
  position: absolute;
  top: -4.5em;
  font-size: 12px;
  right: -1em;
  font-style: italic;
}

.filter-screenshot-compatible {
  position: absolute;
  bottom: -4em;
  font-size: 20px;
  right: -0.5em;
  text-align: right;
  font-weight: bold;
}

.filter-screenshot-compatible>div {
  font-size: small;
  font-weight: normal;
}

.filter-inputs {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0 2em;
}

.filter-inputs>.input-wrapper:last-child {
  padding-bottom: 1em;
}

.input-wrapper {
  margin-bottom: 1em;
}

.filter-inputs>.input-wrapper:last-child {
  margin-top: 1em !important;
}

.filter-inputs>.input-wrapper:last-child>div {
  border: dashed 1px #114f555e;
  border-radius: 0.5em;
  padding: 1em;
}

.input-wrapper .label:first-child {
  min-width: 7em;
}

.footer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  font-size: small;
  color: white;
  background: #005056;
  padding: 0.3em;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: #00000036 0px -1px 8px 0px;
}

.footer a {
  color: #F2B317;
  margin-left: 0.5em;
}

.footer a:hover {
  color: #F2B317;
}

.footer div {
  text-align: center;
}

.footer span {
  margin-left: 0.5em;
  color: #ffffff9e;
}

.footer div:last-child {
  font-size: smaller;
}

.discount-tiers {
  margin-top: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.discount-tiers>div {
  display: flex;
  align-items: center;
  flex: 1;
}

.discount-tiers .label {
  /* margin: 0.5em !important; */
  margin-right: 0.4em !important;
  background: #005056 !important;
  color: white !important;
}

.discount-tiers .button {
  margin: 0.3em !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}